import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWhitelabelsList() {
  // Use toast
  const toast = useToast()
  const refWhitelabelListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, label: "this.$t('labels.name')" },
    { key: 'description', sortable: true, label: "this.$t('labels.description')" },
    { key: 'type', sortable: true, label: "this.$t('labels.type')" },
    { key: 'status', sortable: true, label: "this.$t('labels.status')" },
    { key: 'actions', label: "this.$t('labels.actions')" },
  ]
  const perPage = ref(10)
  const totalWhitelabels = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const recordDeleted = ref(false)
  const statusFilter = ref(true)
  
  const dataMeta = computed(() => {
    const localItemsCount = refWhitelabelListTable.value ? refWhitelabelListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalWhitelabels.value,
    }
  })

  const refetchData = () => {
    refWhitelabelListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter, searchQuery, recordDeleted], () => {
    refetchData()
  })

  const fetchWhitelabels = (ctx, callback) => {
    store.dispatch('app-whitelabel/fetchWhitelabels', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        status: statusFilter.value,
        sortDesc: isSortDirDesc.value
      }).then(response => {
        const { whitelabels, total } = response.data
        callback(whitelabels)
        totalWhitelabels.value = total
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching whitelabel list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveWhitelabelStatusVariant = status => {
    if (status === false) return 'warning'
    if (status === true) return 'success'
    return 'primary'
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchWhitelabels,
    tableColumns,
    perPage,
    currentPage,
    totalWhitelabels,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refWhitelabelListTable,
    refetchData,
    recordDeleted,
    resolveWhitelabelStatusVariant,
    statusFilter
  } 
}
