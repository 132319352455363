<template>
  <div>
    <b-modal
        id="modal-admin-config"
        :title="$t('customization_management')"
        size="lg"
        v-model="showModal"
    >
    <b-overlay :show="loaddingUploadImage">
        <h5>{{ $t('login_background_image') }}</h5>
        <b-form-file
            v-model="imgFile"
            :placeholder="$t('upload_image')"
            :drop-placeholder="$t('drag_here')"
            accept="image/*"
        />
        <b-img
            v-if="imgUrl"
            :src="imgUrl"
            class="w-100"
        />
    </b-overlay>

    <template #modal-footer>
        <div class="w-100">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="float-left"
                @click="showModal = false"
            >
              {{ $t('buttons.cancel') }}
            </b-button>

            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right"
                :disabled="loaddingUploadImage || !cargaImg"
                @click="UploadImg"
            >
              {{ $t('upload') }}
            </b-button>
        </div>
      </template>
    </b-modal>
    <!-- Modal de expiracion -->
    <b-modal v-model="ModalExpired" title="Sesion en la Web">
        <p class="my-4">
            <b-form >
                <input type="hidden" v-model="WlId" >
                <b-form-input
                    id="expiredSession"
                    v-model="expiredSession"
                    required
                    />
                <small>{{errorField}}</small>
            </b-form>
        </p>
        <template #modal-footer>
        <div class="w-100">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="float-left"
                @click="ModalExpired = false"
            >
              {{ $t('buttons.cancel') }}
            </b-button>

            <b-button
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-right"
                @click="SubmitExpired()"
            >
              {{ $t('send') }}
            </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import whitelabelStoreModule from './whitelabelStoreModule'
import axios from '@axios'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import i18n from "@/libs/i18n";

export default {
    components: {
        BModal,
        BCardText,
        BOverlay
    },
    data() {
        return {
            whitalabelData: {},
            imgFile: null,
            imgUrl: null,
            showModal: false,
            WlId: 0,
            expiredSession: 0,
            ModalExpired: false,
            errorField: '',
            loaddingUploadImage: false,
            cargaImg: false,
        }
    },
    directives: {
        Ripple,
    },
    watch: {
        imgFile(newVal) {
            if (newVal) {
                this.cargaImg = true
                this.imgUrl = URL.createObjectURL(newVal)
            } else {
                this.imgUrl = null
            }
        },
        showModal(newVal) {
            if (!newVal) {
                this.imgFile = null
                this.imgUrl = null
                this.cargaImg = false
            }
        }
    },
    setup() {
        const WHITELABEL_APP_STORE_MODULE_NAME = "app-whitelabel";

        // Register module
        if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME))
        store.registerModule(
            WHITELABEL_APP_STORE_MODULE_NAME,
            whitelabelStoreModule
        );

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME))
            store.unregisterModule(WHITELABEL_APP_STORE_MODULE_NAME);
        });
    },
    methods: {
        showModalAdminConfig(whitelabelData) {
            this.whitalabelData = whitelabelData
            this.imgUrl = whitelabelData.wallpaperUrl
            this.showModal = true
        },
        showModalExpired(whitelabelData) {
            const id = whitelabelData._id
            const expiredSession = whitelabelData.expiredSession
            this.WlId = id
            this.expiredSession = expiredSession
            this.ModalExpired = true
        },
        SubmitExpired(){
            const id = this.WlId
            const expiredSession = this.expiredSession
            const URI = process.env.VUE_APP_URL;
            const params = {
                id: id,
                expiredSession: expiredSession
            }
            if( expiredSession == '' ){
                this.errorField = i18n.t('required value')
            }else{
                const result = axios.post(`${URI}/expiredSession`, params)
                .then((r) => ( 
                    this.errorField = i18n.t('successfully_updated')
                ))
                .catch((error) => console.log(error.response));
            }
        },
        async UploadImg () {
            try{
                this.loaddingUploadImage = true
                const formData = new FormData()
                formData.append('images', this.imgFile)
                formData.append('path', 'whitelabel')
                const imageRes = await axios.post(`setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`, formData)
                const wallpaperUrl = imageRes.data.files[0]

                const payload = {
                    wallpaperUrl,
                    id: this.whitalabelData._id
                }
                const savesUrl = await this.$store.dispatch('app-whitelabel/setWallpaper', payload)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: i18n.t('Background_image_loaded'),
                    icon: 'CheckList',
                    variant: 'success',
                    },
                })
                this.showModal = false
            }catch(err){
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: i18n.t('error_loading_image'),
                    icon: 'Error',
                    variant: 'danger',
                    },
                })

            }finally{
                this.loaddingUploadImage = false
            }
        }
    },

}
</script>

<style>

</style>