<template>
  <div>
    <!-- <whitelabel-list-add-new :is-add-new-whitelabel-sidebar-active.sync="
      isAddNewWhitelabelSidebarActive
    " @refetch-data="refetchData" ref="whitelabelListAddNew" /> -->
    <AdminConfig ref="AdminConfig"/>

    <!-- Filters -->
    <!-- <whitelabel-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("labels.show") }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("labels.entries") }}</label>
          </b-col>
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("labels.status") }}</label>
            <v-select v-model="statusFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions" label="label" :reduce="status => status.value"
              :clearable="false" class="d-inline-block w-50 mx-50" />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('buttons.search') + '...'" />
              <div>
                <b-button @click="showModalWhitelabel = true" variant="primary" >
                  <span class="text-nowrap">{{ $t("buttons.add") }}</span>
                </b-button>
                <b-modal hide-footer @hidden="showModalWhitelabel = false" :visible="showModalWhitelabel" id="modal-add-whitelabel" cancel-variant="secondary" ok-only ok-title="Crear" centered size="sm"
                  :title='$t("tabs.addwhitelabels")'>
                  <!-- Whitelabel name -->
                    <b-form-group label="Name" label-for="name">
                      <template #label>{{ $t("labels.name") }}</template>
                      <b-form-input id="name" v-model="whitelabelCreate.name"  trim />
                    </b-form-group>
                  <!-- Description -->
                    <b-form-group label="Description" label-for="description">
                      <template #label>{{ $t("labels.description") }}</template>
                      <b-form-input id="description" v-model="whitelabelCreate.description"  trim/>
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        @click="saveWhitelabel"
                      >
                        {{ $t("buttons.add") }}
                      </b-button>
                    </div>
                </b-modal>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refWhitelabelListTable" class="position-relative" style="min-height: 300px"
        :items="fetchWhitelabels" responsive :fields="fields" primary-key="_id" :sort-by.sync="sortBy" show-empty
        empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span class="text-muted mr-1">{{ data.item.name }}</span>
            <b-badge id="popover-button-sinclient" v-if="data.item.client == null" pill variant="danger">{{ $t('no_client') }}
            </b-badge>
            <b-popover target="popover-button-sinclient" triggers="hover" placement="top" :id="'pope'+data.item._id">
              <template #title>
                <span>{{ $t('whitelabel_no_customer') }}</span>
              </template>
              <span>{{ $t('not_have_a_client') }}</span>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="outline-primary" @click="$router.push({name: 'apps-clients-list'})">
                {{ $t('click_to_assign') }}
              </b-button>
            </b-popover>
          </b-media>
        </template>

        <!-- Column: Description -->
        <template #cell(description)="data">
          <b-media vertical-align="center">
            <span class="text-muted">{{ data.item.description }}</span>
          </b-media>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <b-media vertical-align="center">
            <span class="text-muted">{{ data.item.type }}</span>
          </b-media>
        </template>

        <!-- Column: Currencies -->
        <template #cell(currencies)="data">
          <b-media vertical-align="center" v-for="item in data.item.currencies" :key="item">
            <span class="text-muted">{{ item }}</span>
          </b-media>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveWhitelabelStatusVariant(
              data.item.status
            )}`"
            class="text-capitalize"
          >
            {{ data.item.status ? "Active" : "Inactive" }}
          </b-badge>
        </template>-->
        <template #cell(status)="data">
          <b-form-checkbox name="check-button" @change="updateStatus(data.item)" v-model="data.item.status" switch inline>
          </b-form-checkbox>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(users)="data">
          <b-badge
            pill
            :variant="`light-${resolveWhitelabelStatusVariant(data.item.users)}`"
            class="text-capitalize"
          >
            {{ data.item.users }}
          </b-badge>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item :to="{ name: 'apps-whitelabel-detail', params: { id: data.item._id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{
                  $t("buttons.details")
              }}</span>
            </b-dropdown-item>
            <!--             <b-dropdown-item @click="editShowItem(data.item, 'edit')"> -->
            <b-dropdown-item :to="{ name: 'apps-whitelabels-edit', params: { id: data.item._id, edit: true } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("buttons.edit") }}</span>
            </b-dropdown-item>
<!--            <div>-->
<!--              <b-dropdown-item @click="removeWhitelabel(data.item._id)">-->
<!--                <feather-icon icon="TrashIcon" />-->
<!--                <span class="align-middle ml-50">{{-->
<!--                    $t("buttons.delete")-->
<!--                }}</span>-->
<!--              </b-dropdown-item>-->
<!--            </div>-->
            <b-dropdown-item @click="showModalAdminConfig(data.item, 'show')">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{
                  $t("buttons.admin")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">{{ $t("labels.showing") }} {{ dataMeta.from }}
              {{ $t("labels.to") }} {{ dataMeta.to }} {{ $t("labels.of") }}
              {{ dataMeta.of }} {{ $t("labels.entries") }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalWhitelabels" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBPopover,
  BPopover
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WhitelabelListFilters from "./WhitelabelListFilters";
import useWhitelabelList from "./useWhitelabelList";
import whitelabelStoreModule from "./whitelabelStoreModule";
import WhitelabelListAddNew from "./WhitelabelListAddNew";
import AdminConfig from "./AdminConfig";
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import i18n from "@/libs/i18n";

export default {
  components: {
    WhitelabelListFilters,
    WhitelabelListAddNew,
    AdminConfig,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,

    vSelect,
  },
  data() {
    return {
      whitelabelCreate: {
        name: "",
        description: ""
      },
      showModalWhitelabel: false
    };
  },

  computed: {
    fields() {
      return [
        {key: "name", sortable: true, label: this.$t("labels.name")},
        {
          key: "description",
          sortable: true,
          label: this.$t("labels.description"),
        },
        {key: "type", sortable: true, label: this.$t("labels.type")},
        {key: "status", sortable: true, label: this.$t("labels.status")},
        {key: "actions", label: this.$t("labels.actions")},
      ]

    }
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  setup() {
    const WHITELABEL_APP_STORE_MODULE_NAME = "app-whitelabel";

    // Register module
    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME))
      store.registerModule(
        WHITELABEL_APP_STORE_MODULE_NAME,
        whitelabelStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME))
        store.unregisterModule(WHITELABEL_APP_STORE_MODULE_NAME);
    });

    const isAddNewWhitelabelSidebarActive = ref(false);
    const isAddProductsSidebarActive = ref(false);


    const statusOptions = [
      { label: i18n.t('active'), value: true },
      { label: i18n.t('inactive'), value: false },
    ];

    const {
      fetchWhitelabels,
      tableColumns,
      perPage,
      currentPage,
      totalWhitelabels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWhitelabelListTable,
      refetchData,
      recordDeleted,
      whitelabelData,

      // UI

      resolveWhitelabelStatusVariant,

      // Extra Filters

      statusFilter,
    } = useWhitelabelList();

    return {
      // Sidebar
      isAddNewWhitelabelSidebarActive,
      isAddProductsSidebarActive,

      fetchWhitelabels,
      tableColumns,
      perPage,
      currentPage,
      totalWhitelabels,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refWhitelabelListTable,
      refetchData,
      recordDeleted,
      whitelabelData,
      // UI

      resolveWhitelabelStatusVariant,
      statusOptions,

      // Extra Filters

      statusFilter,
    };
  },
  methods: {
    async saveWhitelabel () {
      try {
        if (this.whitelabelCreate.name === "") {
          this.$toast.error("El nombre es requerido");
          return;
        }
        const { data } = await this.$store.dispatch("app-whitelabel/addWhitelabel", this.whitelabelCreate);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Whitelabel create",
            icon: "CheckListIcon",
            variant: "success",
          },
        });
        this.whitelabelCreate = {
          name: "",
          description: ""
        }
        this.showModalWhitelabel = false;
        this.refetchData();
      } catch (error) {
        console.log("error", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Whitelabel create",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    removeWhitelabel(Id) {
      store.dispatch("app-whitelabel/removeWhitelabel", { id: Id }).then(() => {
        this.recordDeleted = !this.recordDeleted;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Whitelabel removed",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    updateStatus(whitelabel) {
      store.dispatch("app-whitelabel/updateStateWhitelabel", whitelabel).then(() => {
        this.recordDeleted = !this.recordDeleted;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Whitelabel uptated",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    productSidebar(item) {
      this.whitelabelData = item;
      this.isAddProductsSidebarActive = true;
    },
    editShowItem(whitelabel, mode) {
      this.$refs.whitelabelListAddNew.methoEditShow(whitelabel, mode);
      this.isAddNewWhitelabelSidebarActive = true;
    },
    showModalAdminConfig(whitelabel) {
      this.$refs.AdminConfig.showModalAdminConfig(whitelabel);
    },
    showModalExpired(whitelabel) {
      this.$refs.AdminConfig.showModalExpired(whitelabel);
    }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>